<script>
export default {
  data: function () {
    return {
      valueCleaned: null
    }
  },
  created (params) {
    this.valueCleaned = this.booleanCleaner(this.params.value)
  },
  methods: {
    refresh (params) {
      this.valueCleaned = this.booleanCleaner(this.params.value)
    },
    booleanCleaner (value) {
      let returned = false
      if (typeof value === 'boolean') {
        returned = value
      } else if (typeof value === 'undefined' || value === null) {
        returned = null
      } else if (typeof value === 'string') {
        if (value === 'Tout sélectionner') {
          returned = value
        } else {
          returned = value === 'true' // parse 'true' to true or false
        }
      } else if (typeof value === 'object' && Object.keys(value)) { // if object, count if has rows
        returned = !!Object.keys(value).length
      } else {
        returned = null
      }
      return returned
    }
  }
}
</script>

<template>
  <span>
    <i v-if="valueCleaned === true" class="fas fa-check text-green-600"></i>
    <i v-else-if="valueCleaned === false" class="fas fa-times text-red-600"></i>
    <span v-else-if="valueCleaned === 'Tout sélectionner'">{{ valueCleaned }}</span>
    <span v-else>(vide)</span>
  </span>
</template>
